<template>
    <div class="mb-5">
        <v-row>
            <v-col cols="12">
                <v-card class="primary rounded-0">
                    <v-card-text class="px-5 py-3">
                        <div class="d-flex align-center">
                            <v-avatar color="white"
                                      class="mb-0"
                                      size="48">
                                <v-icon large color="primary">mdi-face-profile</v-icon>
                            </v-avatar>
                            
                            <div class="ml-4 mr-1">
                                <h5 class="subtitle-1 white--text">
                                    Total de Perfiles en la Plataforma
                                </h5>
                                <h2 class="white--text font-weight-regular">
                                    {{ totalProfile }}
                                </h2>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                    cols="12"
                    lg="3"
                    sm="6"
                    v-for="(card, index) in cardProfiles"
                    :revenuecard="card"
                    :key="index"
                    class="py-0 mb-3">
            <!-- :class="card.bgcolor" -->
                <!-- :color="getColor()" -->
                <v-card :class="getColor()" class="rounded-0">
                    <v-card-text class="px-2 py-3">
                        <div class="d-flex align-center">
                          <v-avatar color="transparent"
                                    class="mb-0"
                                    size="48">
                            <v-icon large color="white">{{ card.icon }}</v-icon>
                          </v-avatar>
                            
                            <div class="ml-2 mr-1">
                                <h5 class="subtitle-1 white--text text-no-wrap">
                                    {{ card.title }}
                                </h5>
                                <h2 class="white--text font-weight-regular">
                                    {{ card.count }}
                                </h2>
                            </div>
                          <div v-if="card.checklist">
                              <v-btn icon color="white">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                          </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import dashBoardService from '@/providers/DashboardService';
    
    export default {
        name: "TheColorSalesCards",
        data: () => ({
            totalProfile: 0,
            cardProfiles: [],
        }),
        components: {},
        methods: {
            genRandomIndex (length) {
                return Math.ceil(Math.random() * (length - 1))
            },
            getColor() {
                let colors = [
                    "info",
                    "success",
                    "primary",
                    "teal",
                    "deep-purple accent2",
                    "red lighten-1",
                    "pink darken-2",
                    "grey darken-2",
                    "brown",
                    "blue-grey darken-3",
                    "red darken-3",
                    "green"
                ];
                const colorsLength = colors.length;
                let colorsel = this.genRandomIndex(colorsLength);
                return colors[colorsel];
            },
            getCardProfiles() {
                dashBoardService.getProfiles().then(fechData => {
                    // console.log('Datafech: ', fechData);
                    this.totalProfile = fechData.total;
                    this.cardProfiles = fechData.cards;
                });
            }
        },
        mounted() {
            this.getCardProfiles();
        }
    };
</script>